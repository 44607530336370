import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SlackAnalytics from '../views/works/SlackAnalytics.vue'
import WinningElevenWith from '../views/works/WinningElevenWith.vue'
import Sp500 from '../views/works/SP500.vue'
import NotFound from '../views/NotFound.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/works/winningelevenwith',
    name: 'winningelevenwith',
    component: WinningElevenWith
  },
  {
    path: '/works/slackanalytics',
    name: 'slackanalytics',
    component: SlackAnalytics
  },
  {
    path: '/works/sp500',
    name: 'sp500',
    component: Sp500
  },
  // 旧サイト用リダイレクト設定
  {
    path: '/views/sp500.php',
    redirect: '/works/sp500'
  },
  {
    path: '/views/slackanalytics.php',
    redirect: '/works/slackanalytics'
  },
  {
    path: '/views/winningelevenwith.php',
    redirect: '/works/winningelevenwith'
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
