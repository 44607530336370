<template>
    <Header title1="SlackAnalytics" title2=""></Header>
    <div class="contents-wrapper fist-content" id="environment">
            <div class="headline">
                <h3>Environment</h3>
            </div>
            <table class="table table-striped">
                <thead class="table-info">
                    <tr>
                        <th>
                            項目
                        </th>
                        <th>
                            値
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            言語
                        </td>
                        <td>
                            Python
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Webフレームワーク
                        </td>
                        <td>
                            Django
                        </td>
                    </tr>
                    <tr>
                        <td>
                            テストフレームワーク
                        </td>
                        <td>
                            unittest
                        </td>
                    </tr>
                    <tr>
                        <td>
                            DB
                        </td>
                        <td>
                            開発環境：MySQL<br>
                            本番環境：MySQL(JawsDB)
                        </td>
                    </tr>
                    <tr>
                        <td>
                            本番サーバー
                        </td>
                        <td>
                            Heroku×Gunicorn<br>
                            AzureVM×Apache×Gunicorn(現在は利用を停止)
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="contents-wrapper" id="abstract">
            <div class="headline">
                <h3>Abstract</h3>
            </div>
            <p class="intro-message">
                Slackのワークスペース内のメンバーの健康状態を、Slackの利用状況から確認することができるツールです。「拠点」「部署」「チャンネル」「メンバー」を登録することができ、拠点ごとのデータ・部署ごとのデータ・メンバーごとのデータを閲覧することができます。<br>Slackの投稿はSlackAPIより取得しています。<br>現在は大学生時代のインターン先に利用いただいています。
            </p>
        </div>
        <div class="contents-wrapper" id="images">
            <div class="headline">
                <h3>Site Images</h3>
            </div>
            <p class="intro-message">
                以下の画像は実際の拠点別のダッシュボードです。投稿数が多いメンバー・少ないメンバー・直近1ヶ月の投稿チャンネル比率・半年間の週当たり投稿数推移などを閲覧できます。
            </p>
            <div class="site-image">
                <img src="../../assets/works/slackanalytics/dashboard.png" alt="dashboard">
            </div>
            <div class="site-image">
                <img src="../../assets/works/slackanalytics/dashboard_circlegraph.png" alt="dashboard">
            </div>
            <div class="site-image">
                <img src="../../assets/works/slackanalytics/dashboard_linegraph.png" alt="dashboard">
            </div>
        </div>
</template>

<script>
    import Header from '../../components/MainHeader.vue'
    export default {

        name: 'SlackAnalytics',
        components: {
            Header
        },
        data() {
            return {
                
            }
        }
    }
</script>