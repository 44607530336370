<template>
  <div class="home">
    <header>
        <nav>
            <ul>
              <li><a href="#about">{{ aboutme }}</a></li>
              <li><a href="#works">{{ works }}</a></li>
              <li><a href="#skill">{{ skill }}</a></li>
              <li><a href="#contact">{{ contact }}</a></li>
            </ul>
        </nav>
        <div class="site-title">
            <h1>{{ title1 }}</h1>
            <h2>{{ title2 }}</h2>
        </div>
    </header>
    <div class="contents-wrapper fist-content" id="about">
        <div class="headline">
            <h3>{{ aboutme }}</h3>
        </div>
        <div class="profile-container">
            <img src="../assets/myphoto.jpg" alt="">
            <div class="profile">
                <p>静岡県出身のエンジニア。大学では経済学・経営学を学んでいたが、独学で学び始めたプログラミングにのめり込む。その後、プログラミングスクールのインターンを経てエンジニアとしてして就職。現職では、社内業務の自動化・データ連携ツールの開発・製品の保守運用等を担当しています。<br>趣味はコーディング・美術鑑賞(現代アートや新古典主義等)・サッカー・友人とお互いの思考プロセスや価値観を深ぼること。休日は時間を忘れてコードを書いていることが多いです。</p>
                <ul>
                    <li><a href="https://kentech.hatenablog.com/" target="_blank">- 技術ブログ</a></li>
                    <li><a href="https://github.com/k1e1n04?tab=repositories" target="_blank">- Github</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="contents-wrapper" id="works">
        <div class="headline">
            <h3>{{ works }}</h3>
        </div>
        <div class="works-container">
          <router-link to="/works/sp500">
            <div class="work" id="sp_500">
                <img src="../assets/works/sp500/sp500.png" alt="">
            </div>
            <b class="work-title">S&P500 Info</b>
          </router-link>
          <router-link to="/works/slackanalytics">
            <div class="work" id="slackanalytics">
                <img src="../assets/works/slackanalytics/dashboard.png" alt="">
            </div>
            <b class="work-title">SlackAnalytics</b>
          </router-link>
          <router-link to="/works/winningelevenwith">
            <div class="work" id="winningelevenwith">
                <img src="../assets/works/winningelevenwith/winningelevenwith.png" alt="">
            </div>
            <b class="work-title">WinningElevenWith</b>
          </router-link>
        </div>
    </div>
    <div class="contents-wrapper" id="skill">
        <div class="headline">
            <h3>Skill</h3>
        </div>
        <p class="intro-message">日々の学習・業務で身につけたスキルをグラフにまとめました。現在は「とにかく思いついたら触れてみること」を意識して、フロントエンドからバックエンドまで幅広い技術に取り組んでいます。<br>また最近はデザインパターンの学習とオープンソースのコード解析に力を入れています。</p>
        <div class="github">
            <a href="https://github.com/k1e1n04/PythonDesignPattern" target="_blank">- デザインパターン学習用のGithubリポジトリ</a>
        </div>
        <div class="charts">
            <div class="chart">
                <front-chart></front-chart>
                <!-- <div id="front_chart"></div> -->
            </div>
            <div class="chart">
                <back-chart></back-chart>
            </div>
            <div class="chart">
                <devops-chart></devops-chart>
            </div>
        </div>
        <p class="intro-message">数字の基準は「1: 軽く使用した程度」、「2: サポートを受けながら実装できる」、「3: 自立して実装できる」、「4: 十分詳しく実装経験豊富」、「5: 最適なコードを書ける」。という感じです。</p>
    </div>
    <div class="contents-wrapper" id="contact">
        <div class="headline">
            <h3>Contact</h3>
        </div>
        <p class="intro-message">最後までご覧いただきありがとうございました。もしこのサイトや私について何かコメント等ございましたら、下記インスタグラムのDMよりご連絡ください。</p>
        <a href="https://www.instagram.com/kenishii04/" target="_blank">
            <i class="fa-brands fa-square-instagram"></i><br>
        </a>
    </div>
  </div>
</template>

<script>
import FrontRadorChart from '../components/FrontChart.vue'
import BackRadorChart from '../components/BackChart.vue'
import DevOpsRadorChart from '../components/DevOpsChart.vue'
export default {
  name: 'HomeView',
  components: {
    'front-chart': FrontRadorChart,
    'back-chart': BackRadorChart,
    'devops-chart': DevOpsRadorChart
  },
  data() {
    return {
        title1: "Ken Ishii",
        title2: "Portfolio",
        aboutme: "About me",
        works: "Works",
        skill: "Skill",
        contact: "Contact",
    }
  },
}
</script>

<style>
.profile-container{
    margin: 0 auto;
    width: 70%;
}

.profile-container img{
    object-fit: cover;
    width: 150px;
    height: 160px;
    margin-bottom: 2em;
}

.profile {
    word-wrap: break-word;
    text-align: left;
}

.works-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.works-container a{
    display: inline-block;
    width: 50%;
    margin: 10px auto;
}

.work img{
    border: 1px solid lightgrey;
    /* height: 10%; */
    width: 100%;
    object-fit: cover;
}

.intro-message{
  text-align: left;
  margin: 0 auto 20px auto;
  width: 60%;
}

.charts{
    margin: 0 2% 30px auto;
    display: flex;
    flex-wrap: wrap;
}

.chart{
    margin: 0 auto;
}

#skill a{
    display: inline-block;
}

.github{
    text-align: center;
    margin-bottom: 20px;
}

#contact {
    margin-bottom: 30px;
}

#contact a{
    display: block;
    width: 60px;
    margin: 0 auto;
}

#contact i{
    background: linear-gradient(90deg, #4158D0, #C850C0 30%, #FFCC70);
    background: -webkit-linear-gradient(0deg, #4158D0, #C850C0 30%, #FFCC70);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 70px;
}

</style>
