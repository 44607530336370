<template>
    <h4>BackEnd</h4>
    <Radar
    id="back-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Radar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)
import getOption from '../js/ChartOption'
import getData from '../js/ChartData'
const label = [
          "Python",
          "Django",
          "Ruby",
          'Rails',
          "Express",
          "Java",
          "Spring",
          "MySQL",
          "Nginx",
        ]
const data = [4, 3, 3, 3, 2, 3, 2, 4, 2]
const rgbColor = 'rgba(0, 174, 157, 0.2)'
const colorCode = '#00AE9D'
export default {
  name: 'BackRadorChart',
  components: { Radar },
  data() {
    return {
      chartData: getData(label,data,rgbColor,colorCode),
      chartOptions: getOption()
    }
  }
}
</script>