<template>
    <header>
        <nav>
            <ul>
              <li><router-link to="/">{{ top }}</router-link></li>
            </ul>
        </nav>
        <div class="site-title">
            <h1>{{ title1 }}</h1>
            <h2>{{ title2 }}</h2>
        </div>
    </header>
</template>

<script>
    export default {
        name: "TheHeader",
        props: {
            title1: String,
            title2: String
        },
        data() {
            return {
                top: "TOP",
            }
        }
    }
</script>