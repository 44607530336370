<template>
    <h4>FrontEnd</h4>
    <Radar
    id="front-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Radar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)
import getOption from '../js/ChartOption'
import getData from '../js/ChartData'
const label = [
          "HTML/CSS",
          "JavaScript",
          "React",
          'Vue',
          "JQuery",
          "Bootstrap",
        ]
const data = [4, 3, 3, 3, 2, 3]
const rgbColor = 'rgba(255, 99, 132, 0.2)'
const colorCode = '#ff6384'
export default {
  name: 'FrontRadorChart',
  components: { Radar },
  data() {
    return {
      chartData: getData(label,data,rgbColor,colorCode),
      chartOptions: getOption()
    }
  }
}
</script>