<template>
    <Header title1="S&P500 Info" title2=""></Header>
    <div class="contents-wrapper fist-content" id="environment">
        <div class="headline">
            <h3>StockPrice</h3>
            <p>S&P500 の構成銘柄の現在株価、理論株価、現在株価と理論株価の差分を表示します。<br>
                理論株価は DCF 法を用いており、現在株価・企業価値・発行株式数・有利子負債は Yahoo!Finance API より取得しています。<br>
                本ページは正確な現在株価や理論株価を保証するものではございません。参考程度にご覧ください。
            </p>
        </div>
        <table class="table table-striped co12">
            <thead class="table-info">
                <tr>
                    <th>銘柄コード</th>
                    <th>会社名</th>
                    <th>現在株価</th>
                    <th>理論株価</th>
                    <th>現在株価 - 理論株価</th>
                    <th>現在株価の更新日時(UTC)</th>
                </tr>
            </thead>
            <tbody v-html="table">
            </tbody>
        </table>
    </div>
</template>

<script>
    import Header from '../../components/MainHeader.vue'
    function createTable(obj){
        for (const element of obj["res"]) {
            obj["tableElement"] += "<tr><td>"+element["tinker"]+"</td><td>"+element["company_name"]+"</td><td>"+element["price"]+"</td><td>"+element["theorical_price"]+"</td><td>"+ (element["price"] - element["theorical_price"]) +"</td><td>"+element["created_at"]+"</td></tr>"
        }
        return obj
    }
    export default {
        name: 'SP500',
        components: {
            Header
        },
        data() {
            return {
                table: "データを取得しています..."
            }
        },
        async mounted() {
            const url = "https://os8m0sfanh.execute-api.ap-northeast-1.amazonaws.com/default/SP500API";
            const options = {
                method: "GET",
                cache: "no-store"
            };
            fetch(url,options)
            .then(response =>{
                if(!response.ok){
                    throw new Error('Internal Server Error!')
                }
                return response.json()
            })
            .catch(error=>{
                this.table = "API の秒間コール数の制限に達しました。ページを更新して再度データを取得してください。"
                console.error(error);
            })
            .then(json=>{
                return json["data"]
            })
            .then(json=>{
                return {"res":json,"tableElement":""}
            })
            .then(async obj=>{
                obj = await createTable(obj)
                return obj
            }).then(obj=>{
                return obj["tableElement"]
            }).then(tableElement=>{
                this.table = tableElement
            })
        }
    }
</script>
