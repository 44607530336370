<template>
    <footer>
        <p>{{ copyright }}</p>
    </footer>
</template>

<script>
    export default {
        name: "TheFooter",
        data() {
            return {
                copyright: "© 2022 Ken Ishii"
            }
        }
    }
</script>