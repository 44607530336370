<template>
    <header>
        <nav>
            <ul>
              <li><router-link to="/">{{ top }}</router-link></li>
            </ul>
        </nav>
    </header>
    <h1 style="margin-top: 25vh;">404 Not Found</h1>
</template>
<script>
export default {
        name: "NotFound",
        data() {
            return {
                top: "TOP",
            }
        }
    }
</script>