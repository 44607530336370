
function getData(label,data,rgbColor,colorCode) {
    return {
        labels: label,
        datasets: [{
          label: 'Level',
          data: data,
          fill: true,
          backgroundColor: rgbColor,
          borderColor: colorCode,
          pointBackgroundColor: colorCode,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: colorCode
        }]
      }
}
export default getData;