
function getOption() {
    return {
        elements: {
          line: {
            borderWidth: 3
          },
        },
        scales: {
          r: {
              angleLines: {
                  display: false
              },
              suggestedMin: 0,
              suggestedMax: 5
          }
        }
      }
}
export default getOption;