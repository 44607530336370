<template>
  <Header title1="WinningElevenWith" title2=""></Header>
  <div class="contents-wrapper fist-content" id="environment">
    <div class="headline">
        <h3>Environment</h3>
    </div>
    <table class="table table-striped">
        <thead class="table-info">
            <tr>
                <th>
                    項目
                </th>
                <th>
                    値
                </th>
            </tr>
        </thead>
        <tbody>

            <tr>
                <td>
                    言語
                </td>
                <td>
                    Ruby
                </td>
            </tr>
            <tr>
                <td>
                    Webフレームワーク
                </td>
                <td>
                    Ruby on Rails
                </td>
            </tr>
            <tr>
                <td>
                    DB
                </td>
                <td>
                    開発環境：Sqlite3<br>
                    本番環境：PostgreSQL
                </td>
            </tr>
            <tr>
                <td>
                    本番ストレージ
                </td>
                <td>
                    S3
                </td>
            </tr>
            <tr>
                <td>
                    本番サーバー
                </td>
                <td>
                    Heroku(Heroku無料プラン廃止に伴い停止中)
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <div class="contents-wrapper" id="abstract">
        <div class="headline">
            <h3>Abstract</h3>
        </div>
        <p class="intro-message">
            大学3年生の6月に初めて開発したWebアプリケーションです。<br>
            まずは自分の好きなものにまつわるサイトを作ろうと幼少期から続けていたサッカーゲーム「Winning Eleven」にまつわるサイトを作成しました。<br>
            当時友人達と「SNSでWinning Elevenに関する投稿をするのは気がひけるよね」と話していたことをきっかけに、Winning Elevenモバイル版ユーザーの交流の場を作りました。<br>
            Winning Elevenに関する文章・動画や画像を投稿できるだけでなく、ゲーム攻略サイトのように選手を検索し、データを閲覧することができます。
        </p>
    </div>
    <div class="contents-wrapper" id="images">
        <div class="headline">
            <h3>Introduction Video</h3>
        </div>
        <div class="site-image">
            <iframe src="https://drive.google.com/file/d/1zivuaXtZZ3WI3zqQJvGIbxsk_tk3B9VO/preview" width="640" height="480" allow="autoplay"></iframe>
        </div>
    </div>
</template>

<script>
  import Header from '../../components/MainHeader.vue'
  export default {
      name: 'WinningElevenWith',
      components: {
          Header
      },
      data() {
          return {
              
          }
      }
  }
</script>