<template>
  <router-view/>
  <Footer></Footer>
</template>

<script>
  import Footer from './components/MainFooter.vue'
  export default {
    components: {
      Footer
    }
  }
</script>

<style>
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a{
  text-decoration: none !important;
  color: black !important;
}

a:hover{
  border-bottom: solid 2px rgb(17, 184, 222);
}

nav {
  height: 50px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav li{
    font-weight: bold;
    line-height: 50px;
}

nav a.router-link-exact-active {
  color: #42b983;
}

iframe{
  display: block;
  margin: 0 auto;
}

li{
  list-style: none;
}

table {
    max-width: 85%;
    border-collapse: collapse;
    border: solid 1px rgb(211, 211, 211);
    margin: 0 auto;
    word-break: break-all;
    word-wrap: break-all;
}

table td {
    padding: 2px 20px;
    text-align: center;
}

header{
  height: 100px;
}

header ul{
  padding-left: 0;
  text-align: center;
}

header li{
  margin: 0 10px;
  display: inline-block;
}

.site-title {
  padding: 150px 0;
  background-image: url("./assets/durdle_door.jpg");
  background-size: cover;
  background-position: center;
}

.site-title h1{
  font-size: 36px;
}

.site-title h2{
  font-size: 18px;
}

.headline{
  padding: 20px 0;
  text-align: center;
  margin-bottom: 30px;
}

.headline h3{
  font-size: large;
  color: rgb(84, 84, 84);
}

.contents-wrapper{
  margin-top: 70px;
}

.contents-wrapper div {
  max-width: 80%;
  margin: 0 auto;
}

.title{
  display: inline-block;
  margin-left: 10px;
}

.fist-content{
  margin-top: 350px;
}

.site-image{
    width: 80%;
}

.site-image img{
    width: 100%;
    margin-bottom: 1em;
}

footer{
  margin-top: auto;
  height: 70px;
  background-color: rgb(49, 47, 47);
  color: #fff;
}

footer p{
  text-align: center;
  line-height: 70px;
}
</style>
