<template>
    <h4>DevOps</h4>
    <Radar
    id="devops-chart-id"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Radar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)
import getOption from '../js/ChartOption'
import getData from '../js/ChartData'
const label = [
          "Linux",
          "Git/Github",
          "Docker",
          'Azure',
          "AWS",
        ]
const data = [4, 4, 4, 3, 3]
const rgbColor = 'rgba(0, 96, 174, 0.2)'
const colorCode = '#0060ae'
export default {
  name: 'DevOpsRadorChart',
  components: { Radar },
  data() {
    return {
      chartData: getData(label,data,rgbColor,colorCode),
      chartOptions: getOption()
    }
  }
}
</script>